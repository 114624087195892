import * as React from 'react';

import Typography from '@mui/material/Typography';

export default function MediaCard({ topic, variant = "h3", children }) 
{
    return (
        <>
        <Typography variant={variant} paragraph>
            {topic}
        </Typography>
        <Typography variant="body1" gutterBottom>
            {children}
        </Typography>
        </>
    );
}
