import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

setTimeout(() => {
    document.getElementById('continue').focus();
  }, 300);


export default function App()
{
    return (
        <>
        <Box>
            <Typography variant="h2" paragraph>
                Verkkopalveluiden kehitystä
            </Typography>

            <Typography variant="body1" gutterBottom>
                Julkisilla verkkopalveluilla tarkoitetaan internettiin liitettyä palvelua, jonka avulla voidaan esimerkiksi tilata tuotteita palvelun valikoimasta. 
                Tälläisen palvelun toteuttamiseen tarvitaan suunnitelua ja ohjelmiston toteuttamista, mihin me olemme keskittyneet.
            </Typography>
            <Typography variant="body1" gutterBottom>
                <Button id="continue" color="primary" href="/services?tell-me-more">
                    Lue lisää
                </Button>
            </Typography>

        </Box>
        </>
    )

};
