import React from 'react';

import Box from '@mui/material/Box';

//import ServiceIcon from '@mui/icons-material/Cloud';

import Button from '@mui/material/Button';
import ContactIcon from '@mui/icons-material/KeyboardVoice';

import Card from '../Card.js';

setTimeout(() => {
    document.getElementById('continue').focus();
  }, 300);


export default function App()
{
    return (
        <>
        <Box>
            <Card topic="Teemme verkkopalveluita" variant="h2">
            </Card>

            <Card topic="Teemme ylläpitoa verkkopalveluille">
                Verkkopalvelu toimii perinteisellä palvelimella tai pilvipalvemilla. 
                Palvelua kannattaa ylläpitää säännöllisesti, jotta sinne saadaan viimeimmät tiettoturva päivitykset ja havaittua mahdolliset ongelma tilanteet. 
                Autamme myös verkkopalveluiden ylläpitämisen kanssa.
            </Card>

            <Card topic="Teemme DevOps toimintoja">
                Näillä tarkoitetaan tukitoimien edistämistä verkkopalveluiden kehittämisen helpottamiseksi ja myös laadun varmistamisen automasointia. 
                Autamme näiden kehitämisessä ja edistämisessä.
            </Card>
    
            <Card topic="Teemme palvelun siirtoja">
                Kun halutaan siirtää verkkopalvelut pilvipohjaisiin ratkaisuihin tai siirtää toisenlaiseen palvelin ympäristöön. 
                Voimme auttaa myös näiden siirtämisessä ja suunnittelussa.
            </Card>

            <Card topic="Teemme IT-konsultointia">
                IT-konsultointia voidaan tehdä etänä tai asiakkaan tiloissa projektista riippuen.
                Haluamme auttaa ja opastaa verkkopalveluiden kehittämisessä parhampaan suuntaan.
            </Card>

            <Card topic="&nbsp;">
                Ollaan yhteyksissä
                <Button id="continue" color="primary" href="/contact" startIcon={<ContactIcon />}>
                    Ota yhteyttä
                </Button>
            </Card>

        </Box>
        </>
    )
};
