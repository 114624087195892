import React from 'react';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

//import WebIcon from '@mui/icons-material/Web';
import ContactIcon from '@mui/icons-material/KeyboardVoice';
//import ReceiptIcon from '@mui/icons-material/Receipt';
import ServiceIcon from '@mui/icons-material/CloudDone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

/*
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));
  */

const Root = styled('div')(({ theme }) => ({
    margin: theme.spacing(1)
}));


export default function App()
{
    return (
        <Root>
             <Button color="primary" href="/services" startIcon={<ServiceIcon />}>
                Palvelut
            </Button>
            <Button color="primary" href="/contact" startIcon={<ContactIcon />}>
                Ota yhteyttä
            </Button>
            <Button color="primary" href="https://www.linkedin.com/company/rokops/" target="_blank" startIcon={<LinkedInIcon />}>
                LinkedIn
            </Button>
        </Root>
    )
};

