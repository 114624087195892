import React from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { Link } from "react-router-dom";

/*
const useStyles = makeStyles((theme) => ({
    companyLogo: {
        maxWidth: 200,
        maxHeight: 160,
    },
}));
*/
const CompanyLogo = styled('img')(({ theme }) => ({
    maxWidth: 200,
    maxHeight: 160,
}));

const returnLogo = (event, microSeconds) => {
    setTimeout(() => {
        event.target.src = 'img/logo-grayscale.svg';
    }, microSeconds/3);
    event.persist();
    setTimeout(() => {
        event.target.src = 'img/logo.svg';
    }, microSeconds);
}

const MouseOverLogo = (event) => {
    returnLogo(event, 700);
};

const MouseOutLogo = (event) => {
    event.target.src = 'img/logo.svg';
};

export default function Head()
{
    return (
        <Link className="companyLink" color="inherit" to="/">
            <CompanyLogo onMouseOver={MouseOverLogo} onMouseOut={MouseOutLogo}
                alt="RokOps Oy - company logo" src="img/logo.svg" />

            <Typography className="company-name" variant="h1">
                RokOps
            </Typography>
        </Link>
    )
};
