import React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

//import {MDCRipple} from '@mui/ripple';

const Eaddress = styled(Typography)(({ theme }) => ({
    unicodeBidi: "bidi-override",
    direction: "rtl",
    userSelect: "text",
}));
const Elink = styled('a')(({ theme }) => ({
    color: '#00aaf0',
    textDecoration: "none",
}));
const CompanyCode = styled(Typography)(({ theme }) => ({
    userSelect: "text",
}));


function  emailLink(event) {
    let address = event.target.innerHTML

    if (typeof window !== 'undefined') {
        window.location.href = 'mailto:' + address.split('').reverse().join('');
    }
}

setTimeout(() => {
    document.getElementById('continue').focus();
    
    //MDCRipple.attachTo(document.querySelector('.continue'));
  }, 300);



export default function App()
{
    return (
        <>
            <Typography variant="h2" paragraph>
                Yhteystiedot
            </Typography>

            <Box>
                <Typography variant="subtitle1">
                    Rauno Korhonen / RokOps Oy <br/>
                </Typography>
            </Box>

            <Box mb={2}>
                <Typography variant="subtitle2" display="inline">Sähköposti: </Typography>
                <Eaddress variant="body1" display="inline" >
                    <Elink  id="continue" href="?" onClick={emailLink}>if.spokor@ofni</Elink>
                </Eaddress>
            </Box>
            <Box>
                <Typography variant="subtitle2" display="inline">Y-tunnus: </Typography>
                <CompanyCode variant="body1" display="inline">2998083-1</CompanyCode>
            </Box>
            <Box>
                <Typography variant="subtitle2" display="inline">Kotipaikka: </Typography>
                <Typography variant="body1" display="inline">Helsinki</Typography>
            </Box>
        </>
    )

};

