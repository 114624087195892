import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme();

const theme = createTheme({
    palette: {
        primary: {
            main: '#00aaf0',
        },
        secondary: {
            main: '#f0ac00',
        },
        highlight: {
            main: '#66dbff',
        },
        background: {
            default: '#f5fafa',
            light: '#fff',
        },
    },
    typography: {
        fontFamily: "'Droid Sans', 'Archivo', 'Roboto', 'Work Sans', sans-serif",
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 700,
        
        h1: {
            [defaultTheme.breakpoints.up('sm')]: {
                font: '5rem "Viga", sans-serif !important',
                color: '#00aaf0',
                display: 'inline-block',
                verticalAlign: 'top',
                marginTop: 10,
            },
            [defaultTheme.breakpoints.only('xs')]: {
                font: '4rem "Viga", sans-serif !important',
                color: '#00aaf0',
                display: 'inline-block',
                verticalAlign: 'top',
                marginTop: 10,
            },
            [defaultTheme.breakpoints.down('xs')]: {
                font: '2.5rem "Viga", sans-serif !important',
                color: '#00aaf0',
                transform: 'rotate(-90deg)',
                transformOrigin: '60% 120%',
                display: 'inline-block',
                verticalAlign: 'top',
            },
        },
        h2: {
            [defaultTheme.breakpoints.down('md')]: {
                fontSize: '1.5rem',
                font: '2rem "Viga", sans-serif !important',
            },
            [defaultTheme.breakpoints.up('md')]: {
                fontSize: '2.75rem',
                font: '3rem "Viga", sans-serif !important',
            },
        },
        h3: {
            [defaultTheme.breakpoints.down('md')]: {
                fontSize: '1.5rem',
                font: '1rem "Viga", sans-serif !important',
            },
            [defaultTheme.breakpoints.up('md')]: {
                fontSize: '2.75rem',
                font: '2rem "Viga", sans-serif !important',
            },
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                gutterBottom: {
                    marginBottom: defaultTheme.spacing(2.4),
                }
            }
        }
    }
});

export default theme;
