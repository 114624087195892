import React from 'react';

import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import Head from './components/Head';
import FooterMenu from './components/FooterMenu';
import Frontpage from './components/page/Frontpage';
import Services from './components/page/Services';
import Contact from './components/page/Contact';


const Heads = styled(Box)(({ theme }) => ({
    paddingBottom: 0,
    //'-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
}));

const ContainerContent = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.background.light,
    boxShadow: '5px 10px 18px #555',
}));

const ContainerFooter = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.background.light,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 20,
}));


export default function App()
{
    return (
        <Router>
        <Container maxWidth="md">

            <ContainerContent container direction="row" alignItems="center">

                <Grid item xs={12} className="container-item-content fade-to-visible">
                    <Heads p={{ xs: 1, sm: 2, md: 4 }}>
                        <Head />
                    </Heads>

                    <Box p={{ xs: 1, sm: 2, md: 4 }} className="fade-to-visible">
                        <Switch>
                            <Route exact path="/" component={Frontpage} />
                            <Route path="/services" component={Services} />
                            <Route path="/contact" component={Contact} />
                        </Switch>
                    </Box>
                </Grid>
            </ContainerContent>

            <ContainerFooter container direction="row" alignItems="flex-end">
                <Grid item xs={12}>
                    <Box p={{ xs: 1, sm: 2, md: 4 }} >
                        <FooterMenu />
                    </Box>
                </Grid>
            </ContainerFooter>
        </Container>
        </Router>
    );
}

